.decorated-link__inner {
  width: 100%;
  display: block;
  font-size: 1.8rem;
  font-weight: 900;
  text-align: left;

  padding: 2.5rem;
  border-radius: 4rem;
  border: solid var(--light-color) 0.2rem;
  background-color: transparent;
  background-image: url(../../assets/images/home/button_background.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  position: relative;
}

.decorated-link:not(:last-child) {
  margin-bottom: 2.2rem;
}

.decorated-link__text {
  max-width: 22rem;
  line-height: 1.15;
}

.decorated-link__sticker {
  display: block;
  max-width: 12rem;
  position: absolute;
  right: 0;
  top: 0;
}

.decorated-link__sticker img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 359px) {
  .decorated-link__inner {
    font-size: 1.4rem;
  }

  .decorated-link__text {
    max-width: 18rem;
  }

  .decorated-link__sticker {
    max-width: 10rem;
  }
}
