@import "./ResetStyles.css";
@import "./assets/styles/ck-editor.styles.css";
@import "https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap";

:root {
  --background-color: #ffffff;
  --primary-color: #222222;
  --secondary-color: #CFCFCF;
  --light-color: #F4F4F4;
  --accent-color: #FFC03F;
  --accent-light-color: #FBF4E5;
  --error-color: #F2B3B3;
  --dates-color: rgba(34, 34, 34, 0.45);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  background-color: var(--background-color);
  color: var(--primary-color);
}

html {
  /* 62.5% of the base size of 16px = 10px.*/
  font-size: 62.5%;
}

body {
  font-family: "Manrope", sans-serif;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}
