#root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url(../assets/images/home/top_banner.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 18.8rem;
}

.content {
  flex: 1 0 auto;
  padding: 2rem;
  width: 100%;
}

.bottom-banner {
  margin: 0 auto;
  display: block;
  height: 6rem;
  width: 40rem;
  background-image: url(../assets/images/home/bottom_banner.png);
  background-size: 40rem 6rem;
  background-repeat: no-repeat;
  background-position: center;
}

.footer {
  flex: 0 0 auto;
  width: 100%;
  justify-content: center;
}