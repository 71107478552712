.notification {
  background-color: var(--background-color);
  position: fixed;
  bottom: 1.7rem;
  left: 1.7rem;
  right: 1.7rem;
  z-index: 2;

  border-radius: 1.2rem;
  box-shadow: 0 0.4rem 3.4rem 0 rgba(0, 0, 0, 0.16);

  overflow: hidden;
}

.notification__inner {
  padding: 1rem 2rem 0 2rem;
}

.notification__header {
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
}

.notification__sticker {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notification__sticker img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.notification__title {
  font-size: 1.2rem;
  font-weight: 800;
  margin-left: 0.5rem;
}

.notification__progress-wrapper {
  margin-top: 2rem;
  width: 100%;
  height: 0.6rem;
  overflow: hidden;
}

.notification__progress {
  height: 100%;
  background-color: var(--accent-color);
  transition: all 1s linear;
}

.notification__message {
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: 400;
}
